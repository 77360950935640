import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HeroSlider from '../components/HeroSlider';
import SliderAndText from '../components/SliderAndText';
import Layout from '../components/Layout';
import TestimonialSlider from '../components/TestimonialSlider';
import PageHelmet from '../components/PageHelmet';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const TestimonialsPageTemplate = ({
  heroImages,
  heroTitle,
  sliderImages,
  sliderObject,
  seoObject,
  testimonials,
  helmet,
}) => (
  <div className='container content vertically-centered'>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <HeroSlider heroImages={heroImages} heroTitle={heroTitle} />
    <SliderAndText sliderObject={sliderObject} sliderImages={sliderImages} />
    <TestimonialSlider testimonials={testimonials} />
  </div>
);

const TestimonialsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <TestimonialsPageTemplate
        location={location}
        heroImages={frontmatter.heroImages}
        sliderImages={frontmatter.sliderImages}
        sliderObject={frontmatter.sliderObject}
        heroTitle={frontmatter.heroTitle}
        seoObject={frontmatter.seoObject}
        testimonials={frontmatter.testimonials}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImages
              .slice(0, 1)
              .map((item) => item.image)}`}
          />
        }
      />
    </Layout>
  );
};

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TestimonialsPage;

export const pageQuery = graphql`
  query TestimonialsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "testimonials-page" } }) {
      frontmatter {
        heroImages {
          image
          animation
          position
          overlay
          heroTitle
          heroSubtitle
          buttonObject {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
        }
        sliderImages {
          image
        }
        sliderObject {
          title
          text
          button {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
          backgroundTitle
          backgroundTriangle
        }
        testimonials {
          review
          name
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
