import React, { Fragment } from 'react';
import Slider from 'react-slick';
import testimonialQuotes from '../img/testimonials-quotes.svg';
import { HTMLContent } from './Content'
import { v4 } from 'uuid';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialSlider = ({ testimonials }) => {
  const config = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dotsClass: 'slick-dots slider-text-dots',
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className='full-width testimonial-section'>
      <div className='watermark quotation-mark'>
        <img src={testimonialQuotes} />
      </div>
      <Slider {...config}>
        {testimonials.map((testimonial) => (
          <Fragment key={v4()}>
            <div className='container content testimonial-content'>
              <div className='has-text-centered'>
                <div className='review-text'>
                  <HTMLContent className="testimonial-slide-markdown" content={testimonial.review} />
                </div>
                <h4 className='review-name'>{testimonial.name}</h4>
              </div>
            </div>
          </Fragment>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
